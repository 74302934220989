function storage() {

    this.write = function () {

        this.items = this.items.filter(function (value, index, self) {
            return self.indexOf(value) === index;
        });

        localStorage.setItem('viewed', JSON.stringify(this.items));
    };

    this.push = function (data) {
        this.items.push(data);
        this.write();
    };

    this.get = function () {
        return this.items;
    };

    this.items = localStorage.getItem('viewed');
    if (this.items == undefined) {
        this.items = [];
        this.write();
    } else {
        this.items = JSON.parse(this.items);
    }

    return this;

}

var viewed = storage();

$(document).ready(function () {


    // languages tooltip
    var tooltipsterOptions = {
        theme: 'tooltipster-mine',
        contentAsHTML: true,
        functionInit: function (origin, continueTooltip) {
            var content = '<h3>' + origin.data('title') + '</h3>';
            content += '<p>' + origin.data('description') + '</p>';
            return content;
        }
    };

    $('.languages--list a').tooltipster(tooltipsterOptions);


    // projects windows and fake :visited
    $('.projects-holder').on('click', 'a', function () {
        $(this).addClass('viewed');
        viewed.push($(this).data('project-id'));
    }).magnificPopup({
        delegate: 'a',
        type: 'ajax',
        gallery: {
            enabled: true
        },
        callbacks: {
            ajaxContentAdded: function () {
                // (this).addClass('viewed');
                // viewed.push( $(this).data('project-id') );

                var id = $('.project--holder').data('project-id');
                $('.project[data-project-id="' + id + '"]').addClass('viewed');
                viewed.push(id);

                $('.project--slider').cycle({
                    pauseOnHover: true
                });
                $('.project--holder .languages--list a').tooltipster(tooltipsterOptions);
            },

            change: function () {
                $('.project--slider').cycle('destroy');
                $('.project--holder .languages--list a').tooltipster('destroy');
            },

            close: function () {
                $('.project--slider').cycle('destroy');
                $('.project--holder .languages--list a').tooltipster('destroy');
            }
        }
    });

    viewed.get().forEach(function (id) {
        $('.project[data-project-id="' + id + '"]').addClass('viewed');
    });


    // external links to targeted urls
    $('a[target="_blank"]').append('<i class="fa fa-external-link"></i>');


    // contact form
    $('form#contact').on('submit', function () {
        var form = $(this),
            data = $(this).serialize(),
            url = $(this).attr('action');

        // remove any error classes and messages
        $('.error', this).removeClass('error');
        $('.error-msg', this).remove();

        // disable everything
        $('input, textarea', this).attr('disabled', 'disabled');

        $.post(url, data)
            .done(function () {
                $('input, textarea', form).not('[type="submit"]').val('');
                alert('Thanks for the message! I\'ll be in touch');
            })
            .fail(function (rsp) {
                // validation error
                if (rsp.status == 422) {
                    var errors = rsp.responseJSON;

                    for (var field in errors) {
                        if (errors.hasOwnProperty(field)) {
                            $('#'+field)
                                .addClass('error')
                                .after('<p class="error-msg">' + errors[field].join('<br>') + '</p>');
                        }
                    }

                } else {
                    alert('Sorry, unexpected error. Please try again later.');
                }
            })
            .always(function(){
                $('[disabled]').removeAttr('disabled');
            });

        return false;
    });


});